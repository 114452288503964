@import '../../../styles/variables/_colors.scss';
.selected-indices {
  background-color: $grey-800;
  color: $white;
  padding: 0px 40px;
  margin: 0px!important;
  .selected-indices-heading {
    font-size: 2.2em;
    font-weight: bold;
  }
  .heading {
    margin-top: 15px;
  }
  .dark-panel-content {
    background-color: $grey-800;
  }
  .indices-tabs-container {
    padding-bottom: 0px!important;
  }
}
.tab-content {
  margin: 0px!important;
  .tabs-container {
    padding: 0px!important;
  }
}
