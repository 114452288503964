@import '../../../styles/variables/_colors.scss';
.metrics {
  .grid-container {
    display: grid;
    grid-template-columns: auto;
  }

  .negative-number > div > span {
    color: $red-500!important;
  }

  .negative-number-dark > div > span {
    color: $red-600!important;
  }

  .test-metrics:focus {
    border: 2px dotted $blue-200;
  }

  .focus-class:focus {
    border: 2px dotted $blue-500;;
  }

}
