.actions-cell {
  .download-container {
    margin-right: 5px;
    margin-top: 2px;
  }
  .tear-out-container {
    margin-top: 1px;
    margin-left: 9px;

  }
}
