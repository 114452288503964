@import '../../../../styles/variables/_colors.scss';
.summery {
  background-color: $grey-800;
  color: $white;
  padding: 0px 45px 0px 40px;
  margin: 0px!important;
  .selected-indices-heading {
    font-size: 2.2em;
    font-weight: bold;
  }
  .heading {
    margin-top: 15px;
  }
  .dark-panel-content {
    background-color: $grey-800;
  }
  .tools-panel {
    margin: 0px 36px 0px 11px;
  }
  .dates-panel {
    padding-right: 0;
  }
  .metrics-wrapper {
    margin-right: 25px;
  }
  .metrics-title {
    font-size: .8em;
    color: $light-grey-0;
  }
  .metrics-subtitle>div>span {
    font-size: .9em!important;
  }
  .datepicker-container {
    display: inline-grid;
  }
  .metric-and-datepicker-container {
    padding: 0px 0px 0px 0px!important
  }
  .download-button {
    margin-right: 10px;
  }
  .download-container {
    padding: 0px!important;
    padding-right: 40px!important;
  }
  /*metrics classes*/
  .level-metric-header {
    min-width: 120px;
    color: $grey-70;
  }
  .level-metric-header-risk {
    min-width: 140px;
    color: $grey-70;
  }
  .level-column-1 {
    min-width: 100px;
  }
  .level-column-1 > div > span {
    font-size: .9em;
    font-weight: normal;
    color: $white;
    min-width: 100px;

  }
  .level-column-2 > div > span {
    font-size: .9em;
    font-weight: normal;
    color: #64B1E4;
    min-width: 100px;
  }


  .highcharts-container {
    width: 100%!important;
  }
  .chart-container {
    padding-right: 35px!important;
  }
  .level-metrics-heading {
    font-weight: bold;
  }
  .risk-metrics-heading {
    margin-top: 45px;
    font-weight: bold;
  }
  .additional-docs-buttons {
    margin-top: 15px;
    margin-left: -10px;
  }
  .notices-buttons {
    margin-top: 30px;
  }
  .document-buttons {
    text-transform: capitalize;
    text-align: left!important;
    width: fit-content;
  }
  .documents-heading {
    font-weight: bold;
    margin-left: 5px;
    margin-bottom: 5px;
  }
  .document-buttons > span {
    display: inherit;
    margin-top: 4px;
  }

  .document-icon {
    padding: 3px;
    font-size: 15px!important;
    text-decoration: none!important;
    color: $white!important;
    background-color: #175d94;
  }
}
.tab-content {
  margin: 0px!important;
}
.light-panel-content {
  padding: 0px 45px 0px 40px;
  margin: 0px !important;
  .footnotes-heading {
    margin-top: 20px;
    font-size: 1.1em;
    font-weight: bold;
  }
  .common-footnotes-container {
    padding: 0px 42px 0px 14px!important;
    margin: 35px 0px 45px 0px;
  }
}
