body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  background-color: #f2f4f6;
}
.hidden {
  display: none !important;
  visibility: hidden;
}
