@import '../../../styles/variables/_colors.scss';
.indices-listing {
  margin: 35px!important;
  margin-bottom: 70px!important;

  .download-button {
    margin-right: 15px;
  }
  .download-icon {
    margin-right: 5px;
  }
  .indices-grid-search {
    margin-right: 25px;
  }
  .filter-elements {
    margin-top: -15px;
  }

  .toggle-buttons {
    margin-top: -15px;
  }

  .indices-grid {
    width: 100%;
    padding-right: 0px!important;
  }
  .indices-grid > div {
    height: auto;
  }

  .level-color {
    color: $blue-150;
    text-align: right;
    padding-right: 10px!important;
  }
  .level-header {
    text-align: right!important;
  }

  .footnotes-heading {
    margin-top: 20px;
    font-size: 1.1em;
    font-weight: bold;
  }

  .footnotes-container {
    padding: 0px!important;
  }

  .common-footnotes-container {
    padding: 0px!important;
    margin-top: 35px;
  }
}
