div.DayPicker {
  td.CalendarDay__blocked_calendar {
    background: transparent !important;
    color: #61656e !important;
  }
}

.date-select {
  display: flex;
  align-items: center;

  .date-range-picker {
    margin: 0 6px;
    border-bottom-color: transparent;

    input {
      color: #c5c9d0;
      font-size: 14px;
    }
  }

  .date-select-buttons {
    display: inline-flex !important;

    button {
      padding: 0 5px !important;
      margin-right: 2px !important;
      margin-left: 0 !important;
    }
  }
}
