$white: #ffffff;

$off-white: #f2f4f6;

$red: #e32b16;
$red-800: #ca1a06;
$red-500: #FF5942;
$red-600: #ED412A;
$active-red: #9B2416FF;

$mint: #21c6af;

$blue: #2670a9;
$blue-400: #229fe5;
$blue-500: #2670a9;
$blue-100: #519adc;
$blue-150: #2670A9;
$blue-200: #64B1E4;
$blue-400: #2D81BD;
$blue-800: #1368a8;

$red-dark: #ff4136;
$red-light: #e32b16;
$blue-dark: #1f4f72;
$blue-light: #2670a9;

$green: #24874a;

$grey: #f4f5fa;

$light-grey-0: #ccc;
$light-grey-1: #ddd;
$light-grey-2: #eee;

$dark-grey-0: #242526;
$dark-grey-1: #444;
$dark-grey-2: #61656e;
$dark-grey-3: #2f3136;

$grey-10: #f2f4f6;
$grey-15: #f2f2f2;
$grey-20: #eaedef;
$grey-40: #d9dde3;
$grey-70: #b4b7be;
$grey-85: #494e52;
$grey-100: #262728;
$grey-200: #262729;
$grey-300: #4c505b;
$grey-400: #44484f;
$grey-500: #3b3f46;
$grey-600: #2f3136;
$grey-700: #2a2c2f;
$grey-750: #13191f;
$grey-800: #242526;
$grey-900: #161616;

$black: #484d51;

$text-lighter: #9fa3aa;

$color-asset-class-fx: #3289c8;
$color-asset-class-credit: #ea7319;
$color-asset-class-commodity: #1fc2c6;
$color-asset-class-rates: #9e54c0;
$color-asset-class-equity: #a99142;
$color-asset-class-multi-asset: #84878e;

$orange-500: #ea7319;
$orange-light: #ffd77f;
$orange-burnet: #88683d;

$color-blue-light: #b7def6;
$color-pink-light: #ffe3e0;

$cell-highlight-green: #373c30;
$cell-highlight-dot: #adeab2;
$dark-row-bg-color: #343434;
$dark-editable-cell-border: #666666;
$dark-editable-cell-bg-color: #2a2a2a;

$disabled-grey: #eaedef;
$warning-light: #ffe8bf;
$warning: #ea7319;
$grid-auto-group-col-header: #c5c9d0;
$rebalance-legend-active: #ffe769;
$rebalance-legend-inactive: #5e5f63;

$turquoise: #bfe3e7;
$turquoise-light: #d4fbff;
$turquoise-light-10: #c3e5e9;

$profile-button-color: #1c7c9a;
$profile-button-hover-color: #238fb1;

