@import '../../../styles/variables/colors';

.profile-selector {
  .profile-selection-container {
    background-image: url('../../../images/GreyBG.svg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 25px;
    max-width: 420px;
    position: relative;
    margin-right: 2.5em;
  }

  .profile-selection {
    margin-top: 2em;
  }
  .right-container {
    padding: 5em;
    font-size: 1.2em;
    color: $white;
  }

  .profile-button-style {
    background-color: $profile-button-color;
  }
  .profile-button-style:hover {
    background-color: $profile-button-hover-color;
  }

  #landing-text-content:focus {
    border: 2px dotted $blue-500;
    margin: -2px;
  }

}
