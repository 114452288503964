@import '../../../styles/variables/_colors.scss';
.footnotes {
  .accordion-container {
    margin-top: 35px;
  }
  .accordion-panel-heading {
    max-width: 1400px;
  }

  .accordion-panel-heading h3 {
    margin: 5px 0px;
  }


  @media only screen and (max-width: 1400px){
    .accordion-container {
      max-width: calc(100vw - 200px);
      min-width: 200px;
    }
  }

  .accordion-expansion-panel {
    background-color: #eaedef;
  }

  .accordion-expansion-panel .MuiExpansionPanelSummary-expandIcon {
    top: 23px;
  }

  .custom-panel {
    background-color: #f2f4f6;
  }
  .custom-panel > div {
    margin-bottom: 10px;
    background-color: #eaedef;
  }

  .custom-panel a {
    color: $blue-light!important;
  }

  .custom-panel a:active {
    color: $red-dark!important;
  }


}
