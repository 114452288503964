@import '../../styles/variables/_colors.scss';
.notices {
  .notices-accordion {
    margin: 25px 45px 25px 65px;
  }
  .accordion-panel-heading {
    max-width: 1400px;
  }

  .accordion-panel-heading h3 {
    margin: 5px 0px;
  }
  .accordion-container {
    min-width: 900px;
    max-width: 1400px;
    margin: 45px 45px 0px 20px ;
    margin-left: 20px;
    margin-top: 45px;
    margin-right: 45px;
  }

  @media only screen and (max-width: 1400px){
    .accordion-container {
      max-width: calc(100vw - 200px);
      min-width: 200px;
    }
  }

  .accordion-expansion-panel {
    background-color: $white;
  }

  .accordion-expansion-panel .MuiExpansionPanelSummary-expandIcon {
    top: 23px;
  }

  .custom-panel {
    background-color: #f2f4f6;
  }
  .custom-panel > div {
    margin-bottom: 10px;
  }

  .panel-content {
    font-size: 1.2em ;
    margin-bottom: 20px;
  }
  .download-button {
    margin-top: 20px;
    margin-right: 125px;
  }
  .notice-date {
    position: absolute;
    margin: 20px 35px;
    font-size: 1.2em;
  }

  .is-new {
    margin-left: 5px;
    background-color: #dcddde;
    padding: 2px 2px;
    font-size: .8em;
  }

  .accordion-search {
    margin: 45px 20px 0px 35px;
  }
}
