@import '../../../../../styles/variables/_colors.scss';
.documents {
  .documents-grid {
    width: 100%;
    padding-right: 45px!important;
  }
  .documents-grid-container {
    overflow: hidden ;
  }

}
