@import '../../../../styles/variables/_colors.scss';
.reports {
  background-color: $grey-800;
  color: $white;
  padding: 0px 75px 0px 52px;
  margin: 0px!important;
  .selected-indices-heading {
    font-size: 2.2em;
    font-weight: bold;
  }
  .heading {
    margin-top: 15px;
  }

}
.dark-panel-content {
  background-color: $grey-800;
  .metrics-wrapper {
    margin-right: 25px;
  }
  .metrics-title {
    font-size: .8em;
    color: $light-grey-0;
  }
  .metrics-subtitle>div>span {
    font-size: .9em!important;
  }
  .reports-date {
    width: 250px;
    margin-left: 25px;
  }
}
.tab-content {
  margin: 0px!important;
}
.reports-light-panel-content {
  padding: 0px 45px 0px 40px;
  margin: 0px !important;
  .report-filter-elements {
    margin: 25px 45px 13px 0px;
  }
  .reports-grid {
    width: 100%;
    padding-right: 0px!important;
    margin: 0px 25px 0px 0px;
  }
  .reports-grid > div {
    height: auto;
  }
  .footnotes-heading {
    margin-top: 20px;
    font-size: 1.1em;
    font-weight: bold;
  }
  .common-footnotes-container {
    padding: 0px 42px 0px 14px!important;
    margin: 35px 0px 45px 0px;
  }
}
