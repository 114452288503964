@import '../../../../../styles/variables/_colors.scss';
.monthly-return {
  .monthly-return-grid {
    width: 100%;
    padding-right: 45px!important;
  }
  .monthly-return-grid-container {
    overflow: hidden ;
  }
  .number-cell {
    text-align: right;
  }
  .number-cell-header {
    text-align: right;

  }
  .number-cell-header .ag-header-cell-text {
    width: 100%;
  }
  .negative-number {
    color: $red-light;
    text-align: right;
    padding-right: 19px!important;
  }
  .negative-number-dark {
    color: $red-800;
    text-align: right;
    padding-right: 19px!important;
  }
  .positive-number {
    color: $blue;
    text-align: right;
    padding-right: 19px!important;
  }
  .positive-number-dark {
    color: $blue-800;
    text-align: right;
    padding-right: 19px!important;
  }
  .bold-font {
    font-weight: bold;
  }

}
