@import '../../styles/variables/colors';

.landing-page {
  background-image: linear-gradient(180deg, rgba(0,0,0,1), rgba(0,0,0,0)), url('../../images/AttributionChartPostive.svg');
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-size: cover;
  margin: 0px!important;
  .landing-container {
    min-height: calc(100vh - 5.5em);
    margin: 0px;
  }

  .landing-header-container {
    padding-bottom: 0!important;
  }

  .landing-heading {
    color: $white;
  }

.landing-heading-1 {
    width: 220px;
    height: 40px;
    padding-top: 10px;
  }

  .landing-heading-2 {
    margin-top: 1em;
    width: 360px;
    height: 40px;
    padding-top: 10px;
  }

  .landing-heading-1:focus {
    border: 2px dotted $blue-500;
    margin: -2px;
  }

  .landing-heading-2:focus {
    border: 2px dotted $blue-500;
    margin-top: 40px;
    margin-left: -2px;
  }

  .left-container {
    padding: 2em 2em 2em 3em;
    font-size: 3em;
    line-height: 0.2em;
    margin-top: 0.6em;
  }

  .right-container {
    padding: 5em;
    font-size: 1.2em;
    color: $white;
    margin-top: 1em;
  }

}
.selected-profile-display-text {
  padding-right: 45px;
  padding-top: 4px;
  font-size: 12px;
  text-transform: uppercase;
  font-family: jpmuitk-sans;
  font-weight: 600;
  letter-spacing: 0.6px;
}
.selected-profile-display-container {
  width: 100%;
  height: 42px;
  padding-right: 40px;
  padding-top: 5px;
  border-bottom: solid 1px #C5C9D0;
  right: 0px;
  top: 0px;
  font-size: .8em;
  background-color: $white;
  color: $grey-85;
}
.jpmTerms a {
  color: $blue-light;
}
.jpmTerms a:active {
  color: $active-red;
}
