@import 'styles/variables/colors';
.app-nav {
  background-color: $grey-800;
  .tabs-container {
    background-color: $grey-800;
  }
  .app-header {
    display: none;
  }

  #tabs-app-header {
    padding-left: 0;
  }

  #tabs-app-header > div > div {
    justify-content: left;
  }

  .page-heading {
    font-size: 2em;
    font-weight: bold;
    padding: 0.5em 0.5em 0.5em 1em;
    margin-left: .7em;
    margin-bottom: .7em;
    color: $white;
  }
  .page-heading-index {
    font-size: 1em;
    font-weight: bold;
    color: $light-grey-0;
    margin-top: 25px;
  }
  .app-nav-content header {
    background-color: $grey-800;
    box-shadow: none;
  }

}
