@import '../../../styles/variables/_colors.scss';
.footer {
  .app-footer {
    position: fixed;
    bottom: 0;
    height: 2.5em;
    background-color: $grey-200;
    border-top: 1px solid $black;
  }
  .app-footer > span {
    top: 0px;
  }
  .app-footer > span > span > a > svg {
    fill
    : $white;
  }
  .footer-button {
    text-transform: none!important;
    font-size: .8em;
    color: $white!important;
  }
  .footer-button:hover {
    color: $black!important;
  }

  .footer-button> span > a {
    text-decoration: none;
  }
  .copy-right-item {
    z-index: 1000000;
  }

  .toolbar-footer-button > button {
    font-size: .8em;
    text-transform: none;
    color: $white!important;
  }

  .toolbar-footer-button > button:hover {
    color: $black!important;
  }
}
.footer > div > header > div {
  justify-content: flex-start!important;
}
.copy-right-text {
  color: $white;
  padding-right: 50px;
  padding-top: 4px;
  font-size: .8em;
}
.copy-right-container {
  z-index: 100000;
  position: fixed;
  right: 10px;
  bottom: 5px;
  font-size: .8em;
  color: $white;
}


