@import '../../styles/variables/_colors.scss';
.about-us {
  color: $white;
  padding: 0px 40px;
  margin: 0px!important;
  font-size: 1.9em;
  .about-us-heading {
    font-size: 2.2em;
    font-weight: bold;
    margin-top: 15px;
  }
  .panel-content {
    padding: 0px 20px!important;
  }
  p {
    font-size: 1.1em;
    b {
      font-size: 1.1em;
    }
  }
  .heading {
    margin-top: 15px;
  }

  a {
    color: $blue-light;
  }
  a:active {
    color: #fcb9b2 !important;
  }
  .accordion-container {
    min-width: 800px;
    max-width: 800px;
    margin: 0 auto ;
    margin-top: 45px;
    margin-bottom: 70px;
  }

  .accordion-panel-heading h3 {
    font-size: 1.7em;
  }
}
.vida-support {
  color: #2326ef;
}
.contact-us {
  .contact-heading {
    font-size: 1.3em;
    font-weight: bold;
  }
  .contact-content {
    margin-bottom: 20px;
  }
  a {
    color: $blue-light;
  }
  a:active {
    color: $active-red !important;
  }

  div b {
    font-size: 1.2em;
  }
}
.complaints-1 {
  width: 50%;
  float: left;
}
.complaints-2 {
  width: 45%;
  float: left;
  margin-left: 30px;
}
.complaints-sub-heading {
 font-weight: bold;
  font-size: 1.2em;
}
.complaints-heading {
  margin-top: 15px;
  font-size: 2.2em;
  font-weight: bold;
}
.post {
  border: 1px solid grey;
  padding: 5px;
  background-color: #e5e8ea;
  max-width: 350px;
}

