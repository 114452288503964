.file-type-cell {
  .icon-container {
    height: 16px;
    margin-top: 6px;
    margin-left: 20px;
    padding: 1px;
    border-start-end-radius: 6px;
  }
}

