@import '../../../../styles/variables/_colors.scss';
.return-statistics {
    padding: 0px 45px 0px 0px;
    margin: 0px !important;
    .footnotes-heading {
      margin-top: 20px;
      font-size: 1.1em;
      font-weight: bold;
    }
    .common-footnotes-container {
      padding: 0px 42px 0px 14px!important;
      margin: 35px 0px 45px 0px;
    }
    .return-statistic-header {
      font-weight: bold;
      font-size: 1.2em;
    }
    .level-metric-header {
      min-width: 150px;
      color: $grey-900;
    }
    .level-column-1 {
      min-width: 100px;
    }
    .negative-num {
      color: $red-600!important;
    }
  .level-column-1 > div > span {
      font-size: .9em;
      font-weight: normal;
      color: $white;
      min-width: 100px;

    }
    .level-column-2 > div > span {
      font-size: .9em;
      font-weight: normal;
      color: $blue;
      min-width: 100px;
    }

  .sub-header {
    font-weight: normal;
    font-size: .7em;
    color: $grey-400;
    margin-left: 10px;
  }
  .return-statistics-info {
    font-weight: normal;
    font-size: .8em;
    color: $grey-85;
    text-align: right;
  }
}
